.dossier-details {
    .numerotation-delete{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .numerotation{
            margin-top: 10px;
            width: 40px;
            height: 40px;
            border: 1px solid #034b03;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #034b03;
            p{
                color: #fff;
            }
        }
        .nume-delete{
           cursor: pointer;
           transition: all .5s ease-in;
           &:hover{
            color: #034b03;
           }
        //    svg{
        //     width: 100%;
        //       box-shadow: 0px 0px 1px 0px #000
        //    }
        }
}
    .dossier-info{
        width: 100%;
        border-bottom: 4px solid;
        padding-bottom: 10px;
        
    }
    .dossier-info:last-child{
        border: 0;
    }
}