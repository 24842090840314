.Navbar {
  main {
    width: 90%;
    margin: 0 auto;
    padding: 1em 0;
    border-bottom: 1px solid $light-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .hamburger-menu {
        position: relative;
        z-index: 3;
        font-size: 2em;
        display: none;
        cursor: pointer;
        transform: translateY(3px);
        @include desktop() {
          display: block;
        }
      }
      .logo {
        .logo-wrapper {
          display: flex;
          align-items: center;
          gap: 0.5em;
          @include phone() {
            transform: translateY(-2px);
          }
          .logo-img {
            height: 23.3px;
            width: 45px;
            @include phone() {
              // height: 35px;
              // width: 35px;
            }
            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
          .logo-text {
            p {
              &:first-child {
                font-weight: 700;
                font-size: 1.1rem;
                text-transform: uppercase;
                span {
                  color: $orange;
                }
                @include phone() {
                  font-size: 1rem;
                }
              }
              &:last-child {
                font-size: 0.7rem;
                font-family: $inter;
                text-transform: capitalize;
                @include phone() {
                  font-size: 0.6rem;
                }
              }
            }
          }
        }
      }
    }
    .center {
      ul {
        display: flex;
        align-items: center;
        list-style: none;
        @include desktop() {
          display: none;
        }
        li {
          font-size: 0.8rem;
          padding: 0.8em;
          font-weight: 700;
          cursor: pointer;
          transition: color 0.1s ease;
          &:hover {
            color: $primary-green;
          }
        }
        .link-mutation,
        .link-reforme,
        .link-utilisateur {
          position: relative;
          .dropdown-menu {
            position: absolute;
            padding: 0.6em 0.5em;
            top: 100%;
            display: none;
            width: 170px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            background-color: $white;
            li {
              padding: 0.2em 0.5em;
            }
          }
        }

        .link-mutation {
          &:hover {
            .dropdown-mutation {
              display: block;
            }
          }
        }

        .link-reforme {
          &:hover {
            .dropdown-reforme {
              display: block;
            }
          }
        }

        .link-utilisateur {
          &:hover {
            .dropdown-utilisateur {
              display: block;
            }
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 1em;
      .avatar {
        display: flex;
        // align-items: center;
        gap: 0.5em;
        @media screen and (max-width: 450px) {
          display: none;
        }
        .user-info {
          display: flex;
          flex-direction: column;
          text-align: right;
          .name {
            font-size: 0.8rem;
            color: $darker-grey;
          }
          .role {
            font-size: 0.7rem;
            font-weight: 700;
          }
        }
        .user-icon {
          font-size: 1.7em;
        }
      }
      .separator {
        background-color: $dark-grey;
        width: 2px;
        height: 25px;
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
      .logout {
        font-size: 1.5em;
        cursor: pointer;
      }
    }
  }
}
.disconnect{
  cursor: pointer;
}