.nouveau-dossier{
    margin-top: 5px;
    &__header{
       h2{
         font-size: 18px;
         margin-bottom: 10px;
       }
       p{
        font-weight: 400;
        color: #5e5858;
       }
    }
    hr {
        border-style: dotted;
        margin-top: 10px;
    }
    form{
        p{
            font-weight: 400;
            color: #5e5858;
            margin-top: -15px;
        }
        .info-vehicule{
            margin-top: 15px;
        }
        // .input-group:last-child ~ button{
        //     // margin-top: 12px;
        //     background: #2d0303;
        //     width: 100%;
        //     height: 100%;
        // }
    }
  
    &__information-deposant{
        display: flex;
        flex-direction: column;
        .check-btn-grp{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            input{
                width: 40px;
                cursor: pointer;
            }
            label{
                font-size: 16px;
                color: #000;
                width: 280px;
                font-weight: 600;
            }
        }
    }
}
@media screen and (max-width:600px) {
    .nouveau-dossier{
        .check-btn-grp{
            flex-direction: column;
            gap: 10px;
            input{
                
                height: 25px;
                width: 25px;
            }
        } 
    }
}
