.utilisateur{
    width: 80%;
    margin: 2em auto;
}
.head {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.head .title {
    font-weight: 600;
    font-size: 18px;
}