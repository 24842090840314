.retrait .header{
   margin-bottom: .6em;
}
.retrait .header h2{
    margin-bottom: 12px;
}
.retrait .header p {
    font-weight: 500;
    font-size: 14px;
    color: #2b2d42;
}
hr {
    margin-bottom: 15px;
}
input[type="text"],input[type="password"],input[type="tel"],input[type="email"]{
    border-bottom: .5px solid #8d99ae;
    outline: none;
  }
  input[type="text"] {
   max-width: none;
  }
 
.operateur {
    margin-top: 10px;
} 
.operateur .header p {
    color: #8b8b8c;
}
.operateur .input-group span {
    color: #100f0f;
    font-size: 14px;
}
.operateur .input-group .self label{
     color: #100f0f;
     font-size: 14px;
     font-weight: 600;
}