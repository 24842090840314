.gestion-utilisateur-modal {
  .modal {
    @media screen and (min-width: 700px) {
      min-width: 500px;
    }
    @media screen and (max-width: 700px) {
      width: 90%;
    }
  }
  input,
  select {
    padding: 0.5em;
    border: none;
    width: 100%;
    font-weight: 500;
    font-size: 0.75rem;
  }

  .input-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1em;
    margin-top: 0;
    margin-bottom: 0;
    // padding-bottom: 1em;
    // border-bottom: 2px solid rgba(0, 0, 0, 0.069);
  }
}
