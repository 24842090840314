.dossier-details {
    .not-found{
        margin-top: 10px;
        font-size: large;
        font-weight: 500;
    }
    .loading{
        margin: 10px auto;
    }
    button{
        margin-top: 15px;
    }
    &__chassis{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        hr{
            border-style: dotted;
            margin-top: 5px;
        }
        > p{
            margin-top: 10px;
            color: #303130;
        }
        &__body{
             display: flex;
             gap: 32px;
             flex-wrap: wrap;
            
            .field{
            display: flex;
            gap: 10px;
            h2{
                font-size: 16px;
            }
         }
         .chassis-update {
            position: relative;
            
            .edit-btn{
                position: absolute;
                right: -19px;
                top: -19px;
                cursor: pointer;
            }
            .input-edit-chasis{
                position: relative;
                input {
                    border: 1px solid #303130;
                    padding: 0.338em;
                    text-transform: uppercase;
                  
                    padding-right: 10px;
                }
                .input-save-chassis{
                    position:absolute;
                    top: -3px;
                    right: -29.2px;
                    cursor: pointer;
                    svg{
                        width: 32px;
                        height: 100%;
                       
                    }
                }
            }
         }
        }
    }
    &__body{
       display: flex;
       gap: 15px;
        &__left{
            margin-top: 10px;
            width: 50%;
            hr{
                border-style: dotted;
                margin-top: 5px;
            }
            > p{
                margin-top: 10px;
                color: #303130;
            }
            &__header{
                display: flex;
                gap: 5px;
                div{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
              
                h2{
                    font-size: 16px;
                }
               
            }
            .field{
                display: grid;
                grid-template-columns: repeat(auto-fill,160px);
                margin-top: 12px;
                p:nth-child(1){
                    font-weight: 700;
                    color:  #303130;;
                }
            }
        }
        &__right{
            margin-top: 40px;
            width: 50%;
            hr{
                border-style: dotted;
                margin-top: 5px;
            }
            > p{
                margin-top: 10px;
                color: #303130;
            }
            .field{
                display: grid;
                grid-template-columns: repeat(auto-fill,120px);
                margin-top: 12px;
                p:nth-child(1){
                    font-weight: 700;
                    color:  #303130;;
                }
            }
        }
    }
    // &__header{
    //     margin-bottom: 10px;
    //     h3{
    //         font-size: 18px;
    //     }
    // }
}
@media screen and (max-width:480px) {
    .dossier-details {
        &__body{
            display: flex;
            flex-direction: column;
            &__left{
                &__header{
                    flex-direction: column;
                    h2{
                        font-size: 12px;
                    }
                   
                }
            }
            &__right{
                margin-top: 10px;
                width: 50%;
            }
          
        }
    }
}
@media screen and (max-width:720px) {
    .dossier-details {
        &__body{
            display: flex;
            flex-direction: column;
            &__left{
                &__header{
                    flex-direction: column;
                   
                }
            }
        }
    }

}